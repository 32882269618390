var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.blur),expression:"blur"}],staticClass:"base-select",class:{
        'base-select--has-focus': _vm.focus,
        'base-select--is-disabled': _vm.isDisabled,
        'base-select--has-error': _vm.hasError
    }},[(_vm.title.length)?_c('div',{staticClass:"base-select__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('div',{staticClass:"base-select__container"},[(_vm.$slots['icon'])?_c('span',{staticClass:"base-select__icon"},[_vm._t("icon")],2):_vm._e(),_c('label',{attrs:{"for":_vm.id}},[_c('div',{staticClass:"base-select__field base-select__field--background-black",on:{"click":_vm.displayItems}},[(_vm.$slots['icon-inside'])?_c('span',{staticClass:"base-select__icon-inside"},[_vm._t("icon-inside")],2):_vm._e(),_c('input',{staticClass:"base-select__input",attrs:{"id":_vm.id,"type":"text","placeholder":_vm.placeholder,"disabled":""},domProps:{"value":_vm.value}}),_c('ul',{staticClass:"base-select__options",class:{ 'base-select__has-input-search' : _vm.hasInputSearch }},[(_vm.focus)?[(_vm.hasInputSearch)?_c('li',{staticClass:"base-select__option base-select__option--background-black base-select__has-input-search",on:{"keyup":_vm.keysMapList}},[_c('base-input',{attrs:{"placeholder":_vm.placeholder,"value":_vm.searchCriteria,"layout":"search-no-icon"},on:{"input":_vm.updateSearchCriteria,"empty":_vm.clearSearchCriteria,"onInputLoad":_vm.inputFocus},nativeOn:{"click":function($event){$event.stopPropagation();}}})],1):_vm._e(),_vm._l((_vm.filteredListElements),function(option){return _c('li',{key:option.value,staticClass:"base-select__option base-select__option--background-black",class:{
                                'base-select__option--is-selected': _vm.value === option.label,
                                'base-select__option--has-error': option.hasError
                            },attrs:{"data-value":option.value,"data-label":option.label},on:{"click":_vm.setItem}},[_vm._v(" "+_vm._s(option.label)+" ")])}),(_vm.noResultsFound)?_c('li',{staticClass:"base-select__option base-select__no-results base-select__option--background-black"},[_vm._v(" "+_vm._s(_vm.$t(_vm.noResultsFoundText))+" ")]):_vm._e()]:_vm._e()],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }