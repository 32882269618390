<template>
    <div
        class="base-select"
        :class="{
            'base-select--has-focus': focus,
            'base-select--is-disabled': isDisabled,
            'base-select--has-error': hasError
        }"
        v-click-outside="blur"
    >
        <div
            v-if="title.length"
            class="base-select__title">
            {{ title }}
        </div>
        <div class="base-select__container">
            <span
                v-if="$slots['icon']"
                class="base-select__icon">
                <slot name="icon" />
            </span>
            <label :for="id">
                <div
                    class="base-select__field base-select__field--background-black"
                    @click="displayItems"
                >
                    <span
                        v-if="$slots['icon-inside']"
                        class="base-select__icon-inside"
                    >
                        <slot name="icon-inside" />
                    </span>
                    <input
                        :id="id"
                        type="text"
                        class="base-select__input"
                        :value="value"
                        :placeholder="placeholder"
                        disabled
                    >

                    <!-- select list options -->
                    <ul
                        class="base-select__options"
                        :class="{ 'base-select__has-input-search' : hasInputSearch }"
                    >
                        <template v-if="focus">
                            <li
                                v-if="hasInputSearch"
                                class="base-select__option base-select__option--background-black base-select__has-input-search"
                                @keyup="keysMapList"
                            >
                                <base-input
                                    :placeholder="placeholder"
                                    :value="searchCriteria"
                                    layout="search-no-icon"
                                    @input="updateSearchCriteria"
                                    @click.native.stop
                                    @empty="clearSearchCriteria"
                                    @onInputLoad="inputFocus"
                                ></base-input>
                            </li>
                            <li
                                v-for="(option) in filteredListElements"
                                :key="option.value"
                                :data-value="option.value"
                                :data-label="option.label"
                                class="base-select__option base-select__option--background-black"
                                :class="{
                                    'base-select__option--is-selected': value === option.label,
                                    'base-select__option--has-error': option.hasError
                                }"
                                @click="setItem"
                            >
                                {{ option.label }}
                            </li>
                            <li
                                v-if="noResultsFound"
                                class="base-select__option base-select__no-results base-select__option--background-black"
                            >
                                {{ $t(noResultsFoundText) }}
                            </li>
                        </template>
                    </ul>
                </div>
            </label>
        </div>
    </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    export default {
        name: "baseSelect",

        directives: {
            ClickOutside
        },

        components: {
            BaseInput: () => import(/* webpackChunkName: "BaseInput" */ '../BaseInput/BaseInput'),
        },

        data() {
            return {
                focus: false,
                searchCriteria: '',
            }
        },

        props: {
            id: {
                type: String,
                default: ''
            },

            value: {
                type: String,
                default: ''
            },

            title: {
                type: String,
                default: ''
            },

            placeholder: {
                type: String,
                default: ''
            },

            options: {
                type: Array,
                required: true,
                default: () => {
                    return [
                        {label: 'option 1', value: 'option_1'},
                    ]
                }
            },

            isDisabled: {
                type: Boolean,
                required: false
            },

            hasError: {
                type: Boolean,
                default: false
            },

            hasInputSearch: {
                type: Boolean,
                required: false,
            },

            noResultsFoundText: {                
                type: String,
                default: 'no_results_found',
            },
        },

        computed: {
            filteredListElements() {
                if (this.hasInputSearch && this.searchCriteria) {
                    return this.options.filter(element => element.label.toString().toLowerCase().includes(this.searchCriteria.toLowerCase()));
                }

                return this.options;
            },

            noResultsFound() {
                return !this.filteredListElements.length;
            }
        },

        methods: {
            keysMapList(e){
                if(e.key === 'Escape') {
                    this.clearSearchCriteria();
                }

                if(e.key === 'Enter' && this.filteredListElements.length === 1) {
                    this.setItem(this.filteredListElements);
                }
            },

            clearSearchCriteria() {
                this.searchCriteria = '';
            },

            displayItems() {
                !this.isDisabled ? this.focus = !this.focus : null;
                this.clearSearchCriteria();
            },

            setItem(e) {
                if(e.type === 'click') {
                    const { value, label } = e.target.dataset;

                    this.$emit('select', {
                        id: this.id,
                        value,
                        label
                    });

                    return;
                }

                const { value, label } = e[0];

                this.$emit('select', {
                    id: this.id,
                    value,
                    label
                });
            },

            blur() {
                this.focus = false;
            },

            afterEvent(el) {
                el.removeAttribute("style")
            },

            updateSearchCriteria(searchCriteria) {
                this.searchCriteria = searchCriteria;
            },

            inputFocus(input) {
                input.focus();
            },
        },
    }
</script>
<style lang="scss" src="./BaseSelect.scss" scoped></style>
